import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-background.svg"

const HomePersonalTraining = (props) => {
  return (
    <>
      <section className="container container-white">
        <div className="img-section__container img-section__container-r">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A happy older man in a Fitfarm shirt smiling back at the camera"
            className="img-section__container-r__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-left"></div>

        <h2 className=" type-heavy type-black type-header__primary type-header__primary-l">
          <span className="type-emph type-white type-break__after">
            Personal
          </span>{" "}
          Training
        </h2>
        <div className="container-type__white">
          <p className="type-reg type-black type-body">
            Our Omaha coaches are here to help you improve your health through
            fitness and mindfulness. The benefits of having a personal trainer
            are:
          </p>
          <p className="type-reg type-black type-body">
            <span className="type-heavy type-green">Education:</span> In order
            for you to succeed in your fitness life, you'll need to learn about
            the workouts. We will teach you why the movements are important and
            how to perform them without injury. We will teach you everything you
            need to know about exercising and how it relates to your health.
          </p>
          <p className="type-reg type-black type-body">
            <span className="type-heavy type-green">Motivation:</span> Life is
            hard and unpredictable. Whatever the reason, it can be difficult to
            commit to a healthy lifestyle. Regular sessions with our coaches
            will keep you on track and give you the extra boost you need. We
            promise this experience is rewarding and fun.
          </p>
          <p className="type-reg type-black type-body">
            <span className="type-heavy type-green">Personalization:</span> A
            trainer will work with you to set realistic goals. Your plan will be
            based on your overall performance in agility, flexibility, strength,
            and nutrition. All the workouts can be tailored to help offset any
            disabilities you may have and change throughout the year.
          </p>
          <div className="u-mt-2">
            <Link
              to="/personal-training/"
              className="btn btn-primary btn-primary__black type-heavy type-black"
            >
              The benefits of Personal Training
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__white"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default HomePersonalTraining
