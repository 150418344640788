import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-black-background.svg"

const HomeGrowingFitness = (props) => {
  return (
    <>
      <section className="container container-black">
        <div className="img-section__container img-section__container-l">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="Fitfarm female member standing tall after performin a power clean."
            className="img-section__container-l__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-right"></div>

        <h2 className=" type-heavy type-white type-header__primary type-header__primary-r">
          <span className="type-emph type-break__after">Growing</span> Fitness
        </h2>
        <div className="container-type__black">
          <p className="type-reg type-white type-body">
            FitFarm is an Omaha gym for everyone, no matter your age, size, and
            ability. There are many reasons we train, but we all expect the same
            result: <span className="type-heavy type-yellow">Growth.</span>
          </p>
          <p className="type-reg type-white type-body">
            At FitFarm we aim to serve every individual by providing CrossFit
            Classes, Personal Training, and Nutrition guidance. As trainers, we
            seek to make every person grow in the following ways:
          </p>
          <p className="type-reg type-white type-body">
            <span className="type-heavy type-yellow">Ability:</span> Improve
            your strength, agility, health, and flexibility with our fitness
            programs. At FitFarm our trainers are here to support you on your
            fitness journey.
          </p>
          <p className="type-reg type-white type-body">
            <span className="type-heavy type-yellow">Belief:</span> We believe
            you already have the tools you need to accomplish your fitness
            goals, we’re here to help you sharpen them. At FitFarm Omaha, you
            will overcome your anxieties and achieve higher levels of success.
          </p>
          <p className="type-reg type-white type-body">
            <span className="type-heavy type-yellow">Community:</span> FitFarm
            is for everyone. Belonging to a community is important to your
            health. Our Omaha gyms are full of a welcoming and diverse
            community. Here you will make new friends and push each other to the
            finish line.
          </p>

          <Link
            to="/contact-us/"
            className="btn btn-primary btn-primary__gold type-heavy type-black u-mt-2"
          >
            Grow with us
          </Link>
        </div>
        <img
          src={Logo}
          className="img-background img-background__black"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default HomeGrowingFitness
