import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
const HomeCommunity = (props) => {
  return (
    <>
      <BackgroundImage
        Tag="section"
        fluid={props.sectionImage.childImageSharp.fluid}
        className="banner-community u-relative"
      >
        <div className="u-center-text review-container">
          <h2 className="type-header__locations type-emph type-black">
            Fitness <span className="type-heavy">COMMUNITY</span>
          </h2>
          <p className="type-reg type-body">
            Our community is dedicated and diverse. We are open to all men,
            women, and race of any kind. We believe the best way to increase
            your health is with an open mind and group activities. Our members
            will give you friendships and the extra push you need to succeed.
          </p>
        </div>
        <div className="review-position">
          <div className="review-shape">
            <figure className="review-circle ">
              <figcaption className="type-heavy type-yellow review-caption">
                PT
              </figcaption>
            </figure>
            <div className="review-text">
              <h3 className="review-header type-heavy type-black">
                Personal Training - Sasha Shillcutt
              </h3>
              <p className="type-reg type-body review-text__body">
                This is an amazing place where they see the best in you, even
                when you cannot see it yourself. The community of people and
                staff are amazing. Training has been instrumental in strengthing
                my resolve in several areas of my life. I cannot imagine my
                daily routine without it.
              </p>
            </div>
          </div>
          <div className="review-shape u-mt-2">
            <figure className="review-circle ">
              <figcaption className="type-heavy type-green review-caption">
                CF
              </figcaption>
            </figure>
            <div className="review-text">
              <h3 className="review-header type-heavy type-black">
                CrossFit Classes - Brian Van Bloom
              </h3>
              <p className="type-reg type-body">
                I was inactive for many years and consequently was at the lowest
                energy level and highest weight I had ever been. With the
                program, the patience, and persistence, I have realized great
                results. I am truly grateful for finding FitFarm. Everyone here
                has had a tremendous impact.
              </p>
            </div>
          </div>
          <div className="u-center-text u-mt-3">
            <Link
              to="/contact-us"
              className="btn btn-primary btn-primary__black type-heavy type-black"
            >
              Join our Community
            </Link>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default HomeCommunity
