import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-black-background.svg"
const Signup = (props) => {
  return (
    <>
      <section className="banner banner-signup">
        <div className="u-center-text u-pt-3">
          <h2 className="type-header__locations type-emph type-white">
            Three Steps <span className="type-heavy">to Join</span>
          </h2>
        </div>
        <div className="container-signup">
          <div className="banner-signup__img">
            <Img
              fluid={props.sectionImage.childImageSharp.fluid}
              alt="A healthy woman with an excercise balll turning towards the camera."
              className="img-signup"
            />
          </div>
          <div className="signup-steps__container">
            <div className="signup-steps">
              <div className="step step-green">
                <p className="type-heavy type-white step-text">Contact Us</p>
              </div>
              <div className=" step step-white">
                <p className="type-heavy type-green step-white__text">
                  Workout with Us
                </p>
              </div>
              <div className="step step-green">
                <p className="type-heavy type-white step-text">
                  Feel good about yourself
                </p>
              </div>
            </div>
          </div>

          <div className="u-center-text ">
            <Link
              to="/contact-us/"
              className="btn btn-primary btn-primary__gold type-heavy type-black"
            >
              Schedule a Workout
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__black"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default Signup
