import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-white-background.svg"
//Comments
const HomeCrossfitClasses = (props) => {
  return (
    <>
      <section className="container container-white">
        <div className="img-section__container img-section__container-r">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="A fit man in the squat position after perform a dumbell snactch"
            className="img-section__container-r__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-left"></div>

        <h2 className=" type-heavy type-black type-header__primary type-header__primary-l">
          <span className="type-emph type-white type-break__after">
            CrossFit
          </span>{" "}
          Classes
        </h2>
        <div className="container-type__white">
          <p className="type-reg type-black type-body">
            CrossFit uses varied and functional movements to get your heart
            pumping. These classes combine the best of cardio, weightlifting,
            and gymnastics.
          </p>
          <p className="type-reg type-black type-body">
            These high-intensity workouts help you gain muscle, stamina and
            improve your health. The workout of the day, or WOD, continuously
            challenges your muscles because of the variety of exercises your
            will perform.
          </p>
          <p className="type-reg type-black type-body">
            CrossFit enhances your life. This is because the workouts include
            exercises that mimics movements you do in everyday life.
          </p>
          <p className="type-reg type-black type-body">
            CrossFit classes are available Monday through Friday, 5:00am to
            6:30pm. On Saturdays, classes are in the morning and Sundays are
            open gym. You can view our entire{" "}
            <a
              href="https://app.wodify.com/Schedule_API/PublicCalendarEntry.aspx?TenantId=4901&LocationName=&ProgramName="
              className="type-heavy type-green"
              target="__blank"
              rel="external"
            >
              class schedule here.
            </a>
          </p>
          <p className="type-reg type-black type-body">
            Our Membership options include:{" "}
          </p>
          <ul>
            <li className="type-reg type-black type-body li li-green">
              Yearly
            </li>
            <li className="type-reg type-black type-body li li-green">
              Monthly
            </li>
            <li className="type-reg type-black type-body li li-green">
              Weekly
            </li>
            <li className="type-reg type-black type-body li li-green">
              Drop-Ins are welcome!
            </li>
          </ul>
          <div className="u-mt-2">
            <Link
              to="/crossfit/"
              className="btn btn-primary btn-primary__black type-heavy type-black"
              style={{ display: "inline-block" }}
            >
              Learn more about CrossFit
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__white"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default HomeCrossfitClasses
