import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-logo-for-black-background.svg"

const HomeNutrition = (props) => {
  return (
    <>
      <section className="container container-black">
        <div className="img-section__container img-section__container-l">
          <Img
            fluid={props.sectionImage.childImageSharp.fluid}
            alt="Fitfarm female member standing tall after performin a power clean."
            className="img-section__container-l__img"
          />
        </div>
        <div className="rectangle-header rectangle-header__green"></div>
        <div className="skew-header skew-header__green skew-header__green-right"></div>

        <h2 className=" type-heavy type-white type-header__primary type-header__primary-r">
          <span className="type-emph type-break__after">Healthy</span> Nutrition
        </h2>
        <div className="container-type__black">
          <p className="type-reg type-white type-body">
            Our workouts are designed to help you burn more calories than
            traditional workouts. To maximize your health, you'll want to make
            sure you are replenishing your body with the best nutrients.
          </p>
          <p className="type-reg type-white type-body">
            Our nutritional wellness program encourages a balance of lean
            proteins, non-starchy vegetables, nuts, seeds, fruits, and healthy
            fats.
          </p>
          <p className="type-reg type-white type-body">
            The nutrition coaches are trained professionals who will work with
            you to improve your health through dietary, lifestyle, and mindset
            changes. These coaches will educate you about nutrition and food and
            how it relates to health and fitness.
          </p>

          <p className="type-reg type-white type-body">
            We want your health and weight loss program to be fun and enjoyable.
            We are not interested in fad diets or creating plans that are
            impossible to follow. Our diet recommendations taste good and get
            results.
          </p>
          <div className="u-mt-2">
            <Link
              to="/weightloss-and-nutrition/"
              className="btn btn-primary btn-primary__gold type-heavy type-black"
            >
              See our Nutrition Programs
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          className="img-background img-background__black"
          alt="FitFarm Logo reversed nearly transparent"
        />
      </section>
    </>
  )
}

export default HomeNutrition
