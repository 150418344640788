import React from "react"
import Img from "gatsby-image"
import Logo from "assets/images/fitfarm-green-color-logo-only.svg"

const HomeBanner = (props) => {
  console.log("Props ===>", props)
  return (
    <>
      <section className="banner banner-home">
        <div className="banner-home__tl">
          <Img
            fluid={props.leftImage.childImageSharp.fluid}
            alt="A fit woman looking down at her olympic bar"
            className="banner-home__tl-img"
            //style={{ height: "50", width: "50" }}
          />
        </div>
        <div className="banner-home__center">
          <Img
            fixed={props.centerImage.childImageSharp.fixed}
            alt="A fit woman in position to perform a power clean"
            className="nav-logo"
          />
        </div>
        <div className="banner-home__br">
          <Img
            fluid={props.rightImage.childImageSharp.fluid}
            alt="A fit woman in the middle of completing a power clean."
            className="banner-home__br-img"
          />
        </div>
        <div className="banner-header">
          <img
            src={Logo}
            className="banner-header__logo"
            alt="fitfarm green colored logo"
          />
          <h1 className="type type-black type-heavy type-header__banner">
            FITFARM{" "}
            <span className="type type-black type-reg type-header__small">
              Omaha Gym for Strength and Wellness
            </span>
          </h1>
        </div>
      </section>
    </>
  )
}

export default HomeBanner
